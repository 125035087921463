import { createSlice } from "@reduxjs/toolkit";
import { sum, map, filter, uniqBy } from "lodash";
import { store, dispatch } from "../store";
import fileDownload from "js-file-download";
// utils
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  segmentation_tasks: [],
};

const slice = createSlice({
  name: "segmentation_task",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getSegmentationTasksSuccess(state, action) {
      state.isLoading = false;
      state.segmentation_tasks = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// ----------------------------------------------------------------------

export function createSegmentationTask(body) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        "/api/v1/segmentation_task/create",
        body
      );
      dispatch(slice.actions.endLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSegmentationTasks() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/v1/segmentation_task/all");
      dispatch(
        slice.actions.getSegmentationTasksSuccess(
          response.data.segmentation_tasks
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteSegmentationTask(task_id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(
        `/api/v1/segmentation_task/delete/${task_id}`
      );
      dispatch(
        slice.actions.getSegmentationTasksSuccess(
          response.data.segmentation_tasks
        )
      );
      return "Deleted Successfully";
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}
