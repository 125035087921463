// material
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Card,
  Grid,
  Stack,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
// redux
import { useDispatch, useSelector } from "../../../redux/store";
import { downloadFile } from "../../../redux/slices/segmentation";

// components
import fileDownload from "js-file-download";

// ----------------------------------------------------------------------

const SELECTED_CONFIGURATIONS = [
  {
    key: "image_size",
    label: "Image Size",
  },
  {
    key: "area_opening_thresh",
    label: "Area Opening Pixels",
  },
  {
    key: "watershed_dynamic",
    label: "Watershed Dynamic",
  },
  {
    key: "threshold",
    label: "Phase Analysis Threshold",
  },
  {
    key: "threshold_phase_fraction",
    label: "Threshold Phase Fraction",
  },
  {
    key: "px_to_um",
    label: "Pixel Size (px/µm)",
  },
  {
    key: "gb_threshold",
    label: "Grain Boundary Threshold",
  },
];

export default function SelectedConfigurations({ configurations }) {
  const dispatch = useDispatch();

  if (!configurations) return null;

  return (
    <Stack display="flex" direction="row">
      <Box sx={{ width: 280 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Selected Configuration
        </Typography>
      </Box>
      <Typography variant="body" sx={{ mb: 2 }}>
        {SELECTED_CONFIGURATIONS?.map((item, index) => (
          <>
            {configurations?.[item?.key] && (
              <>
                {item?.label} - {configurations?.[item?.key]}
                <br />
              </>
            )}
          </>
        ))}
      </Typography>
    </Stack>
  );
}
