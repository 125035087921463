import { map, filter } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
// utils
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  analytics_data: null,
  companies_analytics: [],
};

const slice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Analytics
    getAnalyticsSummarySuccess(state, action) {
      state.isLoading = false;
      state.analytics_data = action.payload;
    },

    getAnalyticsCompaniesSuccess(state, action) {
      state.isLoading = false;
      state.companies_analytics = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteUser } = slice.actions;

// ----------------------------------------------------------------------

export function getAnalyticsSummary() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/v1/admin/analytics/summary");
      dispatch(slice.actions.getAnalyticsSummarySuccess(response?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAnalyticsCompanies() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/v1/admin/analytics/companies");
      dispatch(
        slice.actions.getAnalyticsCompaniesSuccess(response?.data?.companies)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
