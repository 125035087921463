import { createSlice } from "@reduxjs/toolkit";
import { sum, map, filter, uniqBy } from "lodash";
import { store, dispatch } from "../store";
import fileDownload from "js-file-download";
// utils
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  sortBy: null,
  segmentation: null,
  segmentationList: [],
  segmentation_tasks: [],
  microscopy_profile: [],
};

const slice = createSlice({
  name: "segmentation",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getSegmentationListSuccess(state, action) {
      state.isLoading = false;
      state.segmentationList = action.payload;
    },

    getSegmentationSuccess(state, action) {
      state.isLoading = false;
      state.segmentation = action.payload;
    },

    getSegmentationCreateDetailsSuccess(state, action) {
      state.isLoading = false;
      state.segmentation_tasks = action.payload;
      state.microscopy_profile = action.payload;
    },

    setSegmentation(state, action) {
      state.segmentation = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setSegmentation } = slice.actions;

// ----------------------------------------------------------------------

export function uploadImageForSegmentation(formData) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        "/api/v1/segmentation/upload",
        formData
      );
      dispatch(slice.actions.endLoading());
      return response;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      // return error;
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getSegmentationList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        "/api/v1/segmentation/user-segmentations"
      );
      dispatch(
        slice.actions.getSegmentationListSuccess(response.data.segmentations)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserSegmentationList(user_id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/v1/segmentation/user-segmentations/${user_id}`
      );
      dispatch(
        slice.actions.getSegmentationListSuccess(response.data.segmentations)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSegmentationById(segmentationId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/v1/segmentation/${segmentationId}`
      );
      dispatch(slice.actions.getSegmentationSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function downloadFile(filename) {
  return async () => {
    try {
      const response = await axios.post(
        `/api/v1/segmentation/generate-presigned-url`,
        { filename }
      );
      if (response?.data?.url) {
        const link = document.createElement("a");
        link.href = response?.data?.url;
        link.download = filename; // Name of the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function downloadAllFiles(segmentation) {
  return async () => {
    try {
      const response = await axios.get(
        `/api/v1/segmentation/download/${segmentation?._id}`,
        { responseType: "blob" }
      );
      fileDownload(
        response.data,
        segmentation?.original_filename +
          "-" +
          segmentation?.created_at +
          ".zip"
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// function saveAsFile(text, filename) {
//   // Step 1: Create the blob object with the text you received
//   const type = "application/text"; // modify or get it from response
//   const blob = new BlobBuilder([text], { type });

//   // Step 2: Create Blob Object URL for that blob
//   const url = URL.createObjectURL(blob);

//   // Step 3: Trigger downloading the object using that URL
//   const a = document.createElement("a");
//   a.href = url;
//   a.download = filename;
//   a.click(); // triggering it manually
// }
