import { Suspense, lazy } from "react";
import {
  Navigate,
  useRoutes,
  useLocation,
  useNavigate,
} from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
import RoleBasedGuard from "../guards/RoleBasedGuard";
// components
import LoadingScreen from "../components/LoadingScreen";
import SegmentationList from "pages/dashboard/SegmentationList";
import AdminSegmentationList from "pages/dashboard/Admin/SegmentationList";
import SegmentationDetail from "pages/dashboard/SegmentationDetail";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const navigate = useNavigate();
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          // element: <Login />,
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        // {
        //   path: "register",
        //   element: <Register />,
        //   // element: (
        //   //   <GuestGuard>
        //   //     <Register />
        //   //   </GuestGuard>
        //   // ),
        // },
        // { path: "login-unprotected", element: <Login /> },
        // { path: "register-unprotected", element: <Register /> },
        // { path: "reset-password", element: <ResetPassword /> },
        // { path: "verify", element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "", element: <Navigate to="/dashboard/analytics" /> },
        {
          path: "app",
          element: (
            <RoleBasedGuard accessibleRoles={["user"]}>
              <GeneralApp />
            </RoleBasedGuard>
          ),
        },
        {
          path: "analytics",
          element: (
            <RoleBasedGuard accessibleRoles={["admin"]}>
              <AdminAnalytics />
            </RoleBasedGuard>
          ),
        },
        {
          path: "segmentation_task",
          children: [
            {
              path: "",
              element: (
                <Navigate to="/dashboard/segmentation_task/list" replace />
              ),
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard accessibleRoles={["admin"]}>
                  <SegmentationTaskList />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard accessibleRoles={["admin"]}>
                  <SegmentationTaskCreate />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "microscopy_profile",
          children: [
            {
              path: "",
              element: (
                <Navigate to="/dashboard/microscopy_profile/list" replace />
              ),
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard accessibleRoles={["admin"]}>
                  <MicroscopyProfileList />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard accessibleRoles={["admin"]}>
                  <MicroscopyProfileCreate />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "segmentation",
          children: [
            {
              path: "",
              element: <Navigate to="/dashboard/segmentation/list" replace />,
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard accessibleRoles={["user"]}>
                  <SegmentationList />
                </RoleBasedGuard>
              ),
            },
            {
              path: "detail/:segmentationId",
              element: (
                <RoleBasedGuard accessibleRoles={["user"]}>
                  <SegmentationDetail />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "user",
          children: [
            {
              path: "",
              element: <Navigate to="/dashboard/user/account" replace />,
            },
            {
              path: "account",
              element: <UserAccount />,
            },
            {
              path: "list",
              children: [
                {
                  path: "",
                  element: (
                    <RoleBasedGuard accessibleRoles={["admin"]}>
                      <UserList />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":user_id/segmentation",
                  element: (
                    <RoleBasedGuard accessibleRoles={["admin"]}>
                      <AdminSegmentationList />
                    </RoleBasedGuard>
                  ),
                },
                {
                  path: ":user_id/segmentation/:segmentationId",
                  element: (
                    <RoleBasedGuard accessibleRoles={["admin"]}>
                      <SegmentationDetail />
                    </RoleBasedGuard>
                  ),
                },
              ],
            },
            {
              path: "edit/:userId",
              element: (
                <RoleBasedGuard accessibleRoles={["admin"]}>
                  <UserCreate />
                </RoleBasedGuard>
              ),
            },
            {
              path: "new",
              element: (
                <RoleBasedGuard accessibleRoles={["admin"]}>
                  <UserCreate />
                </RoleBasedGuard>
              ),
            },

            // { path: ":name/edit", element: <UserCreate /> },
          ],
        },

        {
          path: "company",
          children: [
            {
              path: "",
              element: <Navigate to="/dashboard/company/list" />,
            },
            {
              path: "list",
              element: (
                <RoleBasedGuard accessibleRoles={["admin"]}>
                  <CompanyList />
                </RoleBasedGuard>
              ),
            },
            {
              path: "edit/:companyId",
              element: (
                <RoleBasedGuard accessibleRoles={["admin"]}>
                  <CompanyCreate />
                </RoleBasedGuard>
              ),
            },
            {
              path: "create",
              element: (
                <RoleBasedGuard accessibleRoles={["admin"]}>
                  <CompanyCreate />
                </RoleBasedGuard>
              ),
            },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoon /> },
        { path: "maintenance", element: <Maintenance /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
      // children: [
      //   { element: <LandingPage /> },
      //   { path: "about-us", element: <About /> },
      //   { path: "contact-us", element: <Contact /> },
      //   { path: "faqs", element: <Faqs /> },

      // ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
const Register = Loadable(
  lazy(() => import("../pages/authentication/Register"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/authentication/ResetPassword"))
);
const VerifyCode = Loadable(
  lazy(() => import("../pages/authentication/VerifyCode"))
);
// Dashboard
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);
const AdminAnalytics = Loadable(
  lazy(() => import("../pages/dashboard/Admin/AdminAnalytics"))
);
const SegmentationTaskList = Loadable(
  lazy(() => import("../pages/dashboard/Admin/SegmentationTaskList"))
);
const SegmentationTaskCreate = Loadable(
  lazy(() => import("../pages/dashboard/Admin/SegmentationTaskCreate"))
);
const MicroscopyProfileList = Loadable(
  lazy(() => import("../pages/dashboard/Admin/MicroscopyProfileList"))
);
const MicroscopyProfileCreate = Loadable(
  lazy(() => import("../pages/dashboard/Admin/MicroscopyProfileCreate"))
);
const UserProfile = Loadable(
  lazy(() => import("../pages/dashboard/UserProfile"))
);
const UserCards = Loadable(lazy(() => import("../pages/dashboard/UserCards")));
const UserList = Loadable(
  lazy(() => import("../pages/dashboard/Admin/UserList"))
);
const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/UserAccount"))
);
const UserCreate = Loadable(
  lazy(() => import("../pages/dashboard/Admin/UserCreate"))
);
const CompanyList = Loadable(
  lazy(() => import("../pages/dashboard/Admin/CompanyList"))
);
const CompanyCreate = Loadable(
  lazy(() => import("../pages/dashboard/Admin/CompanyCreate"))
);
// Main
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
