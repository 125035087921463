import axios from "axios";

// ----------------------------------------------------------------------

const axiosInstance = axios.create();

// For Direct ENV
axiosInstance.defaults.baseURL =
  process.env.REACT_APP_BACKEND_API_BASE_URL ?? "https://api.webapp-mecs.de/";

// Uncomment below if using docker
// axiosInstance.defaults.baseURL =
//   window?._env_?.REACT_APP_BACKEND_API_BASE_URL ?? "http://localhost:5000/";

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;
